import React, { useState } from 'react';
import Card from './ui/Card';
import { Clock, Activity, TrendingUp, TrendingDown, BarChart2, Table, ArrowRight } from 'lucide-react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import '../index.css';

const STEPS = ['Intake', 'Analysis', 'Development', 'Testing', 'Deployment'];

const DEFAULT_TIMES = {
  current: {
    waitTimes: {
      'Intake': 24,
      'Analysis': 16,
      'Development': 32,
      'Testing': 24,
      'Deployment': 8
    },
    activeTimes: {
      'Intake': 4,
      'Analysis': 8,
      'Development': 40,
      'Testing': 16,
      'Deployment': 2
    }
  },
  future: {
    waitTimes: {
      'Intake': 8,
      'Analysis': 8,
      'Development': 16,
      'Testing': 8,
      'Deployment': 4
    },
    activeTimes: {
      'Intake': 2,
      'Analysis': 6,
      'Development': 32,
      'Testing': 12,
      'Deployment': 2
    }
  }
};

const COLORS = {
  primary: '#00FFFF',
  current: '#00ADBF',
  background: '#CDE0F7',
  highlight: '#F2C044',
  improvement: '#34D399',
  wait: '#22A4DD',
  active: '#70E5E5'
};

const WorkshopVSM = () => {
  const [currentState, setCurrentState] = useState(DEFAULT_TIMES.current);
  const [futureState, setFutureState] = useState(DEFAULT_TIMES.future);
  const [viewMode, setViewMode] = useState('flow');

  // Utility functions
  const formatTime = (hours) => {
    const fullHours = Math.floor(hours);
    const minutes = Math.floor((hours - fullHours) * 60);
    return `${fullHours}h ${minutes}m`;
  };

  const calculateEfficiency = (activeTime, waitTime) => {
    const total = activeTime + waitTime;
    return total > 0 ? ((activeTime / total) * 100).toFixed(1) : '0.0';
  };

  const calculateTotalTimes = (state) => {
    const totalActive = Object.values(state.activeTimes).reduce((a, b) => a + b, 0);
    const totalWait = Object.values(state.waitTimes).reduce((a, b) => a + b, 0);
    return {
      totalActive,
      totalWait,
      totalTime: totalActive + totalWait,
      efficiency: calculateEfficiency(totalActive, totalWait)
    };
  };

  const calculateImprovement = (metric, currentValue, futureValue) => {
    const improvement = ((currentValue - futureValue) / currentValue * 100).toFixed(1);
    return improvement > 0 ? improvement : '0.0';
  };

  const calculateEfficiencyImprovement = (currentActive, currentWait, futureActive, futureWait) => {
    const currentEff = parseFloat(calculateEfficiency(currentActive, currentWait));
    const futureEff = parseFloat(calculateEfficiency(futureActive, futureWait));
    const improvement = (futureEff - currentEff).toFixed(1);
    return improvement > 0 ? `+${improvement}` : improvement;
  };

  // Subcomponents
  const TimeInput = ({ label, value, onChange }) => (
    <div className="time-input">
      <label
        className="label"
        style={{
          color: label === 'Wait' ? COLORS.wait : COLORS.active,
        }}
      >
        {label === 'Wait' ? <Clock size={12} /> : <Activity size={12} />}
        {label}:
      </label>
      <input
        type="number"
        value={value}
        onChange={(e) => onChange(Math.max(0, parseFloat(e.target.value) || 0))}
        className="input"
        min="0"
        step="0.5"
      />
      <span style={{ fontSize: '0.75rem' }}>hrs</span>
    </div>
  );

  const FlowDiagramStep = ({ step, state, setState, color, isFuture, currentValues }) => {
    const activeTime = state.activeTimes[step];
    const waitTime = state.waitTimes[step];
    const efficiency = calculateEfficiency(activeTime, waitTime);
    
    let efficiencyImprovement = null;
    let trendIcon = null;
  
    if (isFuture) {
      const currentEfficiency = calculateEfficiency(currentValues.activeTimes[step], currentValues.waitTimes[step]);
      efficiencyImprovement = (parseFloat(efficiency) - parseFloat(currentEfficiency)).toFixed(1);
      
      if (efficiencyImprovement > 0) {
        trendIcon = <TrendingUp size={16} color="#39FF14" />;
      } else if (efficiencyImprovement < 0) {
        trendIcon = <TrendingDown size={16} color="#ff073a" />;
      }
    }
  
    // Calculate improvements if it's a future state step
    const improvementWait = isFuture ? calculateImprovement('wait', currentValues.waitTimes[step], waitTime) : null;
    const improvementActive = isFuture ? calculateImprovement('active', currentValues.activeTimes[step], activeTime) : null;
  
    return (
      <div className="flow-step" style={{ borderColor: color }}>
        <div style={{ fontSize: '1.5rem', fontWeight: '700', marginBottom: '0.5rem' }}>{step}</div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
          <TimeInput
            label="Wait"
            value={waitTime}
            onChange={(value) => {
              setState((prev) => ({
                ...prev,
                waitTimes: { ...prev.waitTimes, [step]: value },
              }));
            }}
          />
          {/*isFuture && (
            <div className="improvement-display">
              Wait Improvement: {improvementWait}%
            </div>
          )*/}
          <TimeInput
            label="Active"
            value={activeTime}
            onChange={(value) => {
              setState((prev) => ({
                ...prev,
                activeTimes: { ...prev.activeTimes, [step]: value },
              }));
            }}
          />
          {/*isFuture && (
            <div className="improvement-display">
              Active Improvement: {improvementActive}%
            </div>
          )*/}
          <div className="efficiency-display" style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <span>Efficiency: {efficiency}%</span>
            {isFuture && efficiencyImprovement !== null && (
              <>
                {trendIcon}
                <span>{efficiencyImprovement > 0 ? `+${efficiencyImprovement}%` : `${efficiencyImprovement}%`}</span>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  

  
  
  
  

  const FlowDiagram = ({ state, setState, title, color, currentValues }) => {
    const timelineData = STEPS.map(step => ({
      name: step,
      "Wait Time": state.waitTimes[step],
      "Active Time": state.activeTimes[step]
    }));
  
    const axisColor = title === 'Current State' ? COLORS.current : COLORS.highlight;
  
    return (
      <div className="flow-diagram">
        <h3 className="process-title" style={{ color }}>{title}</h3>
        <div className="flow-steps">
          {STEPS.map((step, index) => (
            <React.Fragment key={step}>
              <FlowDiagramStep
                step={step}
                state={state}
                setState={setState}
                color={color}
                isFuture={title === 'Future State'}
                currentValues={currentValues}
              />
              {index < STEPS.length - 1 && (
                <ArrowRight size={24} style={{ flexShrink: 0, color }} />
              )}
            </React.Fragment>
          ))}
        </div>
        <div style={{ height: '12rem' }}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={timelineData} stackOffset="none">
              <XAxis
                dataKey="name"
                stroke={axisColor} // Set the axis color for X-axis
              />
              <YAxis
                label={{
                  value: 'Hours',
                  angle: -90,
                  position: 'insideLeft',
                  fill: axisColor, // Set the axis color for Y-axis label
                }}
                tick={{
                  fill: axisColor, // Set the tick color for Y-axis values
                }}
              />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="Wait Time"
                stackId="1"
                stroke={COLORS.wait}
                fill={COLORS.wait}
              />
              <Area
                type="monotone"
                dataKey="Active Time"
                stackId="1"
                stroke={COLORS.active}
                fill={COLORS.active}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };
  
  

  const currentTotals = calculateTotalTimes(currentState);
  const futureTotals = calculateTotalTimes(futureState);

  return (
    <Card className="container">
      <header className="header">
        {/* Logo aligned to the left */}

        {/* Title centered */}
        <h1 className="title" style={{ flex: 1, textAlign: 'center' }}>FolioSynth Value Stream Optimization: Interactive Demo</h1>
        <h2 className="title" style={{ flex: 1, textAlign: 'center' }}>
          "This demo highlights key techniques for identifying <br />
          inefficiencies and offers opportunities to reduce <br />
          waste while enhancing the process flow."
        </h2>
        {/* Buttons aligned to the right */}
        
      </header>
      <div className="content">
        {viewMode === 'table' ? (
          <>
            {/* Render for "table" mode */}
            <ProcessState
              state={currentState}
              setState={setCurrentState}
              title="Current State"
              color={COLORS.current}
              currentState={currentState}
              isFuture={false}
            />
            <ProcessState
              state={futureState}
              setState={setFutureState}
              title="Future State"
              color={COLORS.highlight}
              currentState={currentState}
              isFuture={true}
            />
          </>
        ) : (
          <>
            {/* Render for "flow" mode */}
            <FlowDiagram
              state={currentState}
              setState={setCurrentState}
              title="Current State"
              color={COLORS.current}
            />
            <FlowDiagram
              state={futureState}
              setState={setFutureState}
              title="Future State"
              color={COLORS.highlight}
              currentValues={currentState}
            />
          </>
        )}

        <div className="comparison">
          <h3 className="section-title">Process Comparison</h3>
          <div className="comparison-grid">

            <div>
              <h4 className="section-title">Current State Totals</h4>
              <div className="metrics-list">
                <div>Wait Time: {formatTime(currentTotals.totalWait)}</div>
                <div>Active Time: {formatTime(currentTotals.totalActive)}</div>
                <div>Process Efficiency: {currentTotals.efficiency}%</div>
              </div>
            </div>

            <div>
              <h4 className="section-title">Future State Totals</h4>
              <div className="metrics-list">
                <div>Wait Time: {formatTime(futureTotals.totalWait)}</div>
                <div>Active Time: {formatTime(futureTotals.totalActive)}</div>
                <div>Process Efficiency: {futureTotals.efficiency}%</div>
              </div>
            </div>

            <div>
              <h4 className="section-title">Time Reduction</h4>
              <div className="metrics-list">
                <div>Wait Time: {calculateImprovement('wait', currentTotals.totalWait, futureTotals.totalWait)}%</div>
                <div>Active Time: {calculateImprovement('active', currentTotals.totalActive, futureTotals.totalActive)}%</div>
                <div>Flow Time: {calculateImprovement('total', currentTotals.totalTime, futureTotals.totalTime)}%</div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <footer style={{ textAlign: 'center' }}>
        Copyright © 2024 FolioSynth LLC
      </footer>
    </Card>
  );
};

export default WorkshopVSM;
